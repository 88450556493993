import {
  InsuranceContext,
  InsuranceState,
  StepContext,
  StepperAction,
  StepperState,
  stepperReducer,
} from '@axo/insurance/feature/providers';
import { useCreateInsuranceKidMandate } from '@axo/shared/data-access/hooks';
import {
  DataAccessContext,
  DataAccessState,
} from '@axo/shared/data-access/provider';
import { insurance, insurance_payment } from '@axo/shared/data-access/types';
import {
  LoanApplicationContext,
  LoanApplicationState,
} from '@axo/shared/feature/providers';
import { useContext } from 'react';
import { create, get, store } from '../../../InsuranceProvider/storedState';
import { completeAction } from './completeAction';

function storeState(
  dataAccessContext: DataAccessState,
  stepContext: StepperState,
  applicationContext: LoanApplicationState,
  insuranceContext: InsuranceState,
  completeStateKey: string
) {
  store(completeStateKey, {
    dataAccessContext,
    stepContext: stepperReducer(stepperReducer(stepContext, completeAction), {
      type: 'Set step',
      scope: { parentType: 'step' },
      payload: 'summary',
    }),
    applicationContext,
    insuranceContext,
  });
}

function dataAction(
  payment: insurance_payment.Payment,
  completeState: string
): StepperAction {
  return {
    type: 'Set step data',
    scope: { parentType: 'verifyPaymentCard' },
    payload: {
      payment: payment,
      isCompleted: false,
      completeStateKey: completeState,
    },
  };
}

export function useCreateInsurancePaymentEpic() {
  const createInsurancePaymentMandate = useCreateInsuranceKidMandate();
  const { state: dataAccessContext } = useContext(DataAccessContext);
  const { state: applicationContext } = useContext(LoanApplicationContext);
  const { state: insuranceContext } = useContext(InsuranceContext);
  const { dispatch, state: stepsContext } = useContext(StepContext);
  const {
    steps: { selectCoverage },
  } = stepsContext;

  function storePaymentState(
    payment: insurance_payment.Payment,
    completeState: string
  ) {
    const stepDataAction = dataAction(payment, completeState);
    dispatch(stepDataAction);
    storeState(
      dataAccessContext,
      stepperReducer(stepsContext, stepDataAction),
      applicationContext,
      insuranceContext,
      completeState
    );
  }

  async function createPayment(insurance: insurance.InsurancePolicy) {
    const { CustomerID, ID: InsuranceID, MarketCountry } = insurance;
    const { url: completeUrl, key: completeState } = create();
    const payment = await createInsurancePaymentMandate.mutateAsync({
      CustomerID,
      InsuranceID,
      MarketCountry,
      CompleteURL: completeUrl,
      Amount: selectCoverage.insurance.payout,
    });
    storePaymentState(payment, completeState);
  }

  function usePayment(payment: insurance_payment.Payment) {
    const { key: completeState } = get(new URL(payment.CompleteURL!));
    storePaymentState(payment, completeState);
  }

  return {
    create: createPayment,
    use: usePayment,
    isLoading: createInsurancePaymentMandate.isLoading,
  };
}
