import { insurance_payment, person } from '@axo/shared/data-access/types';
import { IStepState, StepperData, StepperState } from './reducer';
import { ReactElement } from 'react';

export type IName = Required<Pick<person.Person, 'FirstName' | 'LastName'>>;

export type IAddress = Required<
  Pick<person.Person, 'City' | 'Street' | 'ZipCode'>
>;

export type IPersonData = {
  name: IName;
  address: IAddress;
};

export interface IExplainer extends IStepState<'explainer'> {
  name: 'explainer';
}

export interface ISelectCoverage extends IStepState<'selectCoverage'> {
  name: 'selectCoverage';
  insurance: { payout: number; premium: number };
  isFormShown: { [Property in keyof IPersonData]: boolean };
  personData: {
    address: IAddress;
    name: IName;
  };
}

export interface IPowerOfAttorney extends IStepState<'powerOfAttorney'> {
  name: 'powerOfAttorney';
  hasConfirmedPowerOfAttorney: boolean;
}

export interface ITerms extends IStepState<'terms'> {
  name: 'terms';
  hasConfirmedEligibility: boolean;
  hasConfirmedTerms: boolean;
}

export interface ISummary extends IStepState<'summary'> {
  name: 'summary';
}

export type IPaymentData = {
  payment: insurance_payment.Payment | null;
  isCompleted: boolean;
  paymentStateKey: string | null;
  completeStateKey: string | null;
};

export interface IVerifyPaymentCard
  extends IStepState<'verifyPaymentCard'>,
    IPaymentData {
  name: 'verifyPaymentCard';
}

export type Steps = {
  explainer: IExplainer;
  selectCoverage: ISelectCoverage;
  powerOfAttorney: IPowerOfAttorney;
  terms: ITerms;
  summary: ISummary;
  verifyPaymentCard: IVerifyPaymentCard;
};

export type StepList = Partial<{
  [key in keyof Steps]: ReactElement;
}> & { error?: ReactElement };

export type IStepName = keyof Steps;

export type IStep = Steps[keyof Steps];

export type StepperStateProp = {
  steps: Partial<Steps>;
} & StepperData;

export const getInitialStepperState = (
  initialState: StepperStateProp
): StepperState => {
  return {
    error: initialState.error,
    selectedStep: initialState.selectedStep,
    steps: {
      ...emptyState.steps,
      ...initialState.steps,
    },
  };
};

export const emptyState: StepperState = {
  error: undefined,
  selectedStep: 'explainer',
  steps: {
    explainer: {
      name: 'explainer',
      title: undefined,
      headline: undefined,
      state: 'active',
      isShownInStepper: false,
      isHeadlineShown: false,
    },
    selectCoverage: {
      name: 'selectCoverage',
      title: undefined,
      state: 'untouched',
      insurance: { payout: 0, premium: 0 },
      personData: {
        address: {
          City: '',
          Street: '',
          ZipCode: '',
        },
        name: {
          FirstName: '',
          LastName: '',
        },
      },
      isFormShown: {
        address: false,
        name: false,
      },
      isShownInStepper: false,
      isHeadlineShown: false,
    },
    terms: {
      name: 'terms',
      title: undefined,
      state: 'untouched',
      hasConfirmedEligibility: false,
      hasConfirmedTerms: false,
      isShownInStepper: false,
      isHeadlineShown: false,
    },
    powerOfAttorney: {
      name: 'powerOfAttorney',
      title: undefined,
      state: 'untouched',
      hasConfirmedPowerOfAttorney: false,
      isShownInStepper: false,
      isHeadlineShown: false,
    },
    verifyPaymentCard: {
      name: 'verifyPaymentCard',
      title: undefined,
      state: 'untouched',
      payment: null,
      paymentStateKey: null,
      completeStateKey: null,
      isCompleted: false,
      isShownInStepper: false,
      isHeadlineShown: false,
    },
    summary: {
      name: 'summary',
      title: undefined,
      state: 'untouched',
      isShownInStepper: false,
      isHeadlineShown: false,
    },
  },
};
