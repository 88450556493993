/* eslint-disable axo-frontend/directory-structure */
import { IPayOutAndPremium, payoutAndPremium } from '@axo/insurance/const/no';
import {
  StepperState,
  StepperStateProp,
  getInitialStepperState,
} from '@axo/insurance/feature/providers';
import { SelectCoverageTitle } from '../steps/SelectCoverage/SelectCoverageTitle';
import { useTranslation } from '../translations/useTranslation';

// This rule is disabled as useTranslation is not a hook, it just has a name of the hook.
// When i18n is implemented instead of @axo/shared/util/translation, this function should be replaced to
// const { t } = i18n; // i18n is imported from @axo/shared/localization
// And ideally this should not be in reducer at all, but instead in relevant components.
// eslint-disable-next-line react-hooks/rules-of-hooks
const { t } = useTranslation();

function findCoverage(payout: IPayOutAndPremium['payout']): IPayOutAndPremium {
  const coverage = payoutAndPremium.find((x) => x.payout === payout);
  if (!coverage) return { payout: 0, premium: 0 };
  return coverage;
}

const state: StepperStateProp = {
  error: undefined,
  selectedStep: 'explainer',
  steps: {
    explainer: {
      name: 'explainer',
      title: t('A safe choice'),
      headline: t('Secure your economy'),
      state: 'active',
      isShownInStepper: true,
      isHeadlineShown: true,
    },
    selectCoverage: {
      name: 'selectCoverage',
      title: <SelectCoverageTitle />,
      state: 'untouched',
      insurance: findCoverage(0),
      personData: {
        address: {
          City: '',
          Street: '',
          ZipCode: '',
        },
        name: {
          FirstName: '',
          LastName: '',
        },
      },
      isFormShown: {
        address: false,
        name: false,
      },
      isShownInStepper: true,
      isHeadlineShown: true,
    },
    terms: {
      name: 'terms',
      title: t('Consent'),
      state: 'untouched',
      hasConfirmedEligibility: false,
      hasConfirmedTerms: false,
      isShownInStepper: true,
      isHeadlineShown: true,
    },
    verifyPaymentCard: {
      name: 'verifyPaymentCard',
      title: t('Accept and pay'),
      state: 'untouched',
      payment: null,
      paymentStateKey: null,
      completeStateKey: null,
      isCompleted: false,
      isShownInStepper: false,
      isHeadlineShown: false,
    },
  },
};

export const initialState: StepperState = getInitialStepperState({
  ...state,
});

export const newFlowStepInitialState: StepperState = getInitialStepperState({
  ...state,
  selectedStep: 'selectCoverage',
});
