import { EventCode, useEventLogger } from '@axo/shared/data-access/event-log';
import { useEffect, useRef } from 'react';
import { useCreateInsuranceEvent } from '../InsurancePolicyProvider';
import { useAnalytics } from '@axo/shared/services/analytics';
import { StepperState } from './store/reducer';

export const useInsuranceLogging = (state: StepperState) => {
  const log = useEventLogger();
  const prev = useRef<(typeof state)['selectedStep'] | 'summary|'>();
  const addEvent = useCreateInsuranceEvent();
  const { track } = useAnalytics();

  useEffect(() => {
    const isSummaryStep =
      state.selectedStep === 'terms' &&
      state.steps.verifyPaymentCard.isCompleted;
    const selectedStep = isSummaryStep ? 'summary' : state.selectedStep;

    if (selectedStep !== prev.current) {
      if (prev.current === undefined) {
        track({
          event: `Insurance Initiated`,
        });
      }

      if (selectedStep === 'summary') {
        track({
          event: `Insurance Completed`,
          params: { amount: state.steps.selectCoverage.insurance.payout },
        });
      }

      prev.current = state.selectedStep;

      addEvent('form/view-step', state.selectedStep);

      log(EventCode.ViewStep, { step: state.selectedStep });

      track({
        event: `Insurance Step ${state.selectedStep} Viewed`,
        params: { step: state.selectedStep },
      });
    }
  }, [
    addEvent,
    log,
    state.selectedStep,
    state.steps.selectCoverage.insurance.payout,
    state.steps.verifyPaymentCard.isCompleted,
    track,
  ]);
};
