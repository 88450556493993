import {
  useLoanApplication,
  useLoanApplicationFromMagicToken,
} from '@axo/shared/data-access/hooks';
import { DataAccessContext } from '@axo/shared/data-access/provider';
import { LoanApplicationContext } from '@axo/shared/feature/providers';
import { useAnalytics } from '@axo/shared/services/analytics';
import { useContext, useEffect } from 'react';

export const useApplication = () => {
  const { identify } = useAnalytics();

  const {
    state: userState,
    dispatch: userDispatch,
    url,
  } = useContext(DataAccessContext);
  const { dispatch } = useContext(LoanApplicationContext);

  const search = new URLSearchParams(window.location.search);
  const magicToken = search.get('m');
  const uid = search.get('uid');
  let id = search.get('id');
  if (id === 'null') id = null;

  // Load Application
  const loanApplicationResult = useLoanApplication(id);

  // Magic Token flow
  const magicTokenResult = useLoanApplicationFromMagicToken(
    url.api,
    userState.user?.token ? null : magicToken
  );

  useEffect(() => {
    let ignore = false;

    if (!magicTokenResult.data || ignore) return;

    const application = magicTokenResult.data;

    // Set User from Magic Token
    if (!userState.user?.token) {
      userDispatch({
        type: 'Set auth',
        scope: { parentType: 'user' },
        payload: {
          JWT: application.JWT,
        },
      });
    }

    dispatch({
      type: 'Set application',
      scope: { parentType: 'application' },
      payload: application,
    });

    identify({ uuid: application.CustomerID, email: userState?.user?.email });

    return () => {
      ignore = true;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [magicTokenResult.isSuccess]);

  useEffect(() => {
    let ignore = false;

    if (!loanApplicationResult.data || ignore) return;

    const application = loanApplicationResult.data;

    dispatch({
      type: 'Set application',
      scope: { parentType: 'application' },
      payload: {
        ...application,
        LegacyID: Number(uid),
      },
    });

    identify({ uuid: application.CustomerID, email: userState?.user?.email });

    return () => {
      ignore = true;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loanApplicationResult.isSuccess]);

  if (uid) {
    if (isNaN(Number(uid)))
      return {
        error: new Error(`Cannot Fetch Application: UID is not a number`),
      };
  }

  if (!id && !magicToken) {
    return {
      error: new Error(
        `Cannot Fetch Application: No ID or Magic Token provided on the URL`
      ),
    };
  }

  const error = loanApplicationResult.error || magicTokenResult.error;

  if (error) {
    return {
      error: new Error(`Failed to load Application: ${error}`),
    };
  }

  return {
    isLoading: loanApplicationResult.isLoading || magicTokenResult.isLoading,
  };
};
