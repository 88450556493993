import { LoadedLoanApplication } from '../LoadedLoanApplication';
import { LoanApplicationAction } from './actions';

export type LoanApplicationState = {
  error: { error: Error; showHelp?: boolean } | undefined;
  application: LoadedLoanApplication | null;
  insurancePolicyReference?: {
    PolicyID: string;
  } | null;
};

export const loanApplicationInitialState: LoanApplicationState = {
  error: undefined,
  application: null,
};

export function reducer(
  state: LoanApplicationState,
  action: LoanApplicationAction
): LoanApplicationState {
  switch (action.type) {
    case 'Set application': {
      return {
        ...state,
        application: { ...state.application, ...action.payload },
      };
    }
    case 'Set error':
      return { ...state, error: action.payload };
    default:
      return state;
  }
}
