import {
  Checkbox,
  ContentBox,
  Icons,
  KeyBenefits,
} from '@axo/deprecated/util/ui-components';
import {
  InsuranceContext,
  InsuranceFeatureFlags,
  StepContext,
  type InsuranceFeatureFlagsValues,
} from '@axo/insurance/feature/providers';
import {
  ActionBar,
  NavWrapper,
  StepNavigator,
  ValidationContentBox,
} from '@axo/insurance/ui';
import {
  EventCode,
  useEventField,
  useEventLogger,
} from '@axo/shared/data-access/event-log';
import {
  useInsurancePaymentMandates,
  useInsurancePolicy,
} from '@axo/shared/data-access/hooks';
import { insurance_payment } from '@axo/shared/data-access/types';
import { useAnalytics } from '@axo/shared/services/analytics';
import {
  useFlagImpression,
  useFlags,
} from '@axo/shared/services/feature-flags';
import { useContext, useRef, useState } from 'react';
import stepStyles from '../../StepProvider.module.scss';
import { getValidState } from '../../getValidState';
import { useTranslation } from '../../translations/useTranslation';
import { AvtaleGiroModal } from '../AvtaleGiro/AvtaleGiroModal';
import { useCreateInsurancePaymentEpic } from '../AvtaleGiro/useInsurancePaymentEpic';
import { SwitchWithLabels } from './SwitchWithLabels';
import styles from './Terms.module.scss';
import { MyPageFlags } from '@axo/mypage/util';
import clsx from 'clsx';
import { Card } from '@axo/ui-core/components/Card';
import { currencyFormatter } from '../../currency';
import { Icon } from '@axo/ui-core/components/Icon';

function canUsePayment(x: insurance_payment.Payment) {
  return (
    x.Status === insurance_payment.Status.Pending ||
    x.Status === insurance_payment.Status.Completed
  );
}

export const Terms = () => {
  const { dispatch, state, isEmbedded } = useContext(StepContext);
  const {
    steps: { selectCoverage: selectData },
  } = state;
  const { t } = useTranslation();
  const log = useEventLogger();
  const { track } = useAnalytics();
  useEventField('current-step', 'terms');
  const checkboxes = useRef<HTMLDivElement>(null);
  const { 'show-new-insurance-modal': flag_show_new_insurance_modal } =
    useFlags(MyPageFlags);
  let {
    flag_ins_step_header_position,
    flag_ins_terms_benefits_icon,
    flag_ins_terms_checkbox_style,
    flag_ins_terms_cta,
  } = useFlags(InsuranceFeatureFlags) as InsuranceFeatureFlagsValues;
  useFlagImpression(InsuranceFeatureFlags, {
    flag_ins_step_header_position,
    flag_ins_terms_benefits_icon,
    flag_ins_terms_checkbox_style,
    flag_ins_terms_cta,
  });
  flag_ins_step_header_position ??= 'top';
  flag_ins_terms_benefits_icon ??= 'plain';
  flag_ins_terms_checkbox_style ??= 'checkbox';
  flag_ins_terms_cta ??= 'Buy insurance and proceed to payment';
  const {
    steps: { terms: stepData },
  } = state;
  const {
    state: { insurancePolicyReference },
  } = useContext(InsuranceContext);
  const insurancePolicy = useInsurancePolicy(
    insurancePolicyReference?.PolicyID ?? undefined
  );

  const insurancePayment = useCreateInsurancePaymentEpic();
  const paymentsForPolicy = useInsurancePaymentMandates(
    insurancePolicyReference?.PolicyID ?? '',
    insurancePolicy.data?.MarketCountry ?? ''
  );

  const [triedContinue, setTriedContinue] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  function getExistingPayment() {
    const prev = state.steps.verifyPaymentCard.payment;

    if (prev && canUsePayment(prev)) {
      return prev;
    }

    const usable = (paymentsForPolicy.data?.Items ?? []).filter(canUsePayment);
    const sorted = usable.sort(
      (a, b) => +new Date(b.CreatedAt) - +new Date(a.CreatedAt)
    );

    if (sorted.length) {
      return sorted[0];
    }

    return null;
  }

  const enableNextButton =
    insurancePolicy.data &&
    stepData.hasConfirmedEligibility &&
    stepData.hasConfirmedTerms;

  async function handleNext() {
    if (!insurancePolicy.data) return;
    setTriedContinue(true);
    if (stepData.hasConfirmedEligibility && stepData.hasConfirmedTerms) {
      log(EventCode.InsuranceNext);
      dispatch({
        type: 'Set step data',
        scope: { parentType: stepData.name },
        payload: { ...stepData, state: 'completed' },
      });
      await track({
        event: `Insurance Step ${stepData.name} Completed`,
        options: {
          send_immediately: true,
        },
      });

      const prev = getExistingPayment();

      if (prev) {
        insurancePayment.use(prev);
      } else {
        insurancePayment.create(insurancePolicy.data);
      }
      setIsModalVisible(() => true);
    } else {
      checkboxes.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }

  function handleBack() {
    log(EventCode.InsuranceBack);
    dispatch({
      type: 'Set step data',
      scope: { parentType: stepData.name },
      payload: { ...stepData, state: 'touched' },
    });
    dispatch({
      type: 'Set step',
      scope: { parentType: 'step' },
      payload: 'selectCoverage',
    });
  }

  const CheckboxComponent =
    flag_ins_terms_checkbox_style === 'switch' ? SwitchWithLabels : Checkbox;

  return (
    <>
      <AvtaleGiroModal
        isVisible={isModalVisible}
        onCloseModal={() => setIsModalVisible(() => false)}
      />

      {flag_show_new_insurance_modal && (
        <Card className={styles.contentCard}>
          <div className={styles.summary}>
            <div className={styles.summaryLabel}>
              <Icon name="check-circle-solid" size="sm" />
              {t('Security insurance - monthly cost')}
            </div>
            <div className={styles.summaryValue}>
              {currencyFormatter(selectData.insurance.premium)}/{t('Month')}
            </div>
          </div>
        </Card>
      )}

      <ContentBox
        classes={{
          root: clsx(
            stepStyles.contentContainer,
            flag_show_new_insurance_modal && styles.contentCard
          ),
        }}
      >
        <div className={styles.content}>
          {flag_ins_step_header_position === 'inside' && (
            <h4 className={styles.stepTitle}>{stepData.title}</h4>
          )}
          <div className={styles.keyBenefits}>
            <KeyBenefits
              icon={
                flag_ins_terms_benefits_icon === 'circle' ? (
                  <div style={{ marginTop: '-4px', marginBottom: '4px' }}>
                    <Icons.CircleCheckMarkValid size="sm" />
                  </div>
                ) : undefined
              }
              benefits={[
                t('18-65 years old'),
                t('I live permanently in Finland'),
                t(
                  'I am on a permanent employment relationship and work at least 16 hours a week'
                ).toString(),
                t(
                  'I am 100 % capable and I am not aware of future lay-off or dismissal'
                ).toString(),
                t(
                  'I am not aware of any illness or chronic condition'
                ).toString(),
              ]}
            />
          </div>
          <ValidationContentBox
            active={
              stepData.hasConfirmedEligibility && stepData.hasConfirmedTerms
            }
            invalid={
              triedContinue &&
              !(stepData.hasConfirmedEligibility && stepData.hasConfirmedTerms)
            }
            classes={{
              root: clsx(flag_show_new_insurance_modal && styles.validationBox),
            }}
          >
            <div ref={checkboxes}>
              <CheckboxComponent
                name="hasConfirmedEligibility"
                onChange={(e) =>
                  dispatch({
                    type: 'Set step data',
                    scope: { parentType: stepData.name },
                    payload: {
                      hasConfirmedEligibility:
                        typeof e === 'boolean' ? e : e.target.checked,
                    },
                  })
                }
                errors={getValidState(
                  stepData.hasConfirmedEligibility,
                  triedContinue,
                  t('To proceed, you must be eligible')
                )}
                classes={{ root: styles.checkboxSpacer }}
                value={stepData.hasConfirmedEligibility}
              >
                {t('I will meet the above conditions')}
              </CheckboxComponent>
              <CheckboxComponent
                name="hasConfirmedTerms"
                onChange={(e) =>
                  dispatch({
                    type: 'Set step data',
                    scope: { parentType: stepData.name },
                    payload: {
                      hasConfirmedTerms:
                        typeof e === 'boolean' ? e : e.target.checked,
                    },
                  })
                }
                errors={getValidState(
                  stepData.hasConfirmedTerms,
                  triedContinue,
                  t('To proceed, you must accept the terms')
                )}
                value={stepData.hasConfirmedTerms}
              >
                {t('I understand that security insurance is valid despite')}
              </CheckboxComponent>
            </div>
          </ValidationContentBox>
        </div>
      </ContentBox>
      <NavWrapper
        alwaysStickyNavButtons={isEmbedded}
        className={clsx(flag_show_new_insurance_modal && styles.actions)}
      >
        {flag_show_new_insurance_modal ? (
          <ActionBar
            backButton={{
              text: t('Back'),
              onClick: handleBack,
            }}
            nextButton={{
              text: t('Next'),
              onClick: handleNext,
              disabled: !enableNextButton,
            }}
          />
        ) : (
          <StepNavigator
            leftButton={{
              width: 'full-width',
              text: t('Go back'),
              variant: 'back',
              onClick: () => handleBack(),
            }}
            rightButton={{
              width: 'full-width',
              text: t(flag_ins_terms_cta),
              variant: enableNextButton ? 'enabled' : 'disabled',
              onClick: () => handleNext(),
            }}
          />
        )}
      </NavWrapper>
    </>
  );
};
