import {
  Icons,
  LoadingSpinner,
  Modal,
} from '@axo/deprecated/util/ui-components';
import { AxoSupportPhoneNumber } from '@axo/insurance/const/no';
import { useInsurancePaymentMandate } from '@axo/shared/data-access/hooks';
import { ReactElement, useContext, useEffect } from 'react';
import { useTranslation } from '../../translations/useTranslation';
import styles from './AvtaleGiroModal.module.scss';
import { StepContext } from '@axo/insurance/feature/providers';

export const AvtaleGiroModal = (props: {
  isVisible: boolean;
  onCloseModal: () => void;
}): ReactElement | null => {
  const { t } = useTranslation();
  const { state } = useContext(StepContext);
  const paymentId = state.steps.verifyPaymentCard.payment?.ID;

  const payment = useInsurancePaymentMandate(
    paymentId as string,
    false,
    paymentId !== undefined
  );

  useEffect(() => {
    if (!props.isVisible) return;
    if (!payment?.data?.PaymentURL) return;
    window.location.replace(payment.data.PaymentURL);
  }, [payment.data, payment.data?.PaymentURL, props.isVisible]);

  const Logo = () => {
    return (
      <div className={styles.logo}>
        <Icons.AvtaleGiro size="full" />
      </div>
    );
  };

  const Spinner = () => {
    return (
      <div className={styles.spinner} role="progressbar">
        <LoadingSpinner colorVariable="--dark-700" />
      </div>
    );
  };

  const AvtaleGiroText = () => {
    return <span className={styles.bold}>AvtaleGiro</span>;
  };

  const Information = () => {
    return <p>{t('AvtaleGiro Info', [AvtaleGiroText()])}</p>;
  };

  const ClickHere = () => {
    return (
      <span className={styles.link} onClick={props.onCloseModal}>
        {t('Here')}
      </span>
    );
  };

  const ContactAndSupport = () => {
    return (
      <p>
        {t('AvtaleGiro Contact and Support', [
          <ClickHere />,
          <AvtaleGiroText />,
          AxoSupportPhoneNumber,
        ])}
      </p>
    );
  };

  const ModalContent = () => {
    return (
      <div>
        <Logo />
        <Spinner />
        <Information />
        <ContactAndSupport />
      </div>
    );
  };

  if (!props.isVisible) {
    return null;
  }

  return <Modal topBorderColor="--light-100" children={ModalContent()} />;
};
