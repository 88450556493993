import {
  FC,
  MouseEventHandler,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ICSSColorVariable } from '../../../models/color.model';
import classNames from '../../../utils/class-names';
import { getScrollbarSize } from '../../../utils/getScrollbarWidth';
import Button, { IButton } from '../Button';
import { Icons } from '../Icons';
import { H3 } from '../StyledElements';
import styles from './Modal.module.scss';

export interface IModal {
  topBorderColor?: ICSSColorVariable;
  children: ReactNode;
  onBackgroundClick?: MouseEventHandler<HTMLElement>;
  icon?: ReactNode;
  title?: string;
  buttons?: [IButton] | [IButton, IButton];
  classes?: ClassNamesProp;
  onCloseButton?: () => void;
}

type ClassNamesProp = {
  root?: string;
  box?: string;
  buttons?: string;
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const Modal: FC<IModal> = ({
  topBorderColor,
  children,
  onBackgroundClick,
  icon,
  title,
  buttons,
  classes,
  onCloseButton,
  ...props
}: IModal) => {
  const bodyScrollStyleRef = useRef({
    position: document.body.style.getPropertyValue('position'),
    paddingRight: document.body.style.getPropertyValue('padding-right'),
    top: document.body.style.getPropertyValue('top'),
    width: document.body.style.getPropertyValue('width'),
  });

  // used to add correct class after component mount to have initial animation.
  const [hasMounted, setHasMounted] = useState<boolean>();

  useEffect(() => {
    const fixateBodyScroll = () => {
      if (typeof window === 'undefined' || typeof document === 'undefined')
        return;
      document.body.style.setProperty(
        'padding-right',
        `${getScrollbarSize(document)}px`
      );
      const scrollY = window.scrollY;
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollY}px`;
      document.body.style.width = '100%';
    };

    const resetBodyScroll = () => {
      if (typeof window === 'undefined' || typeof document === 'undefined')
        return;
      document.body.style.paddingRight =
        bodyScrollStyleRef.current.paddingRight || '';
      const scrollY = document.body.style.top;
      document.body.style.position = bodyScrollStyleRef.current.position || '';
      document.body.style.top = bodyScrollStyleRef.current.top || '';
      document.body.style.width = bodyScrollStyleRef.current.width || '';
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    };
    setHasMounted(true);
    // Prevent background scrolling when modal is visible.
    fixateBodyScroll();
    return () => resetBodyScroll();
  }, []);

  return (
    <div
      className={classNames(
        styles.background,
        hasMounted && styles.mounted,
        classes?.root
      )}
      onClick={(e) => {
        onBackgroundClick?.(e);
      }}
      {...props}
    >
      <div
        className={classNames(styles.box, classes?.box)}
        style={topBorderColor && { borderColor: `var(${topBorderColor})` }}
        onClick={(e) => e.stopPropagation()}
      >
        {icon && <div className={styles.icon}>{icon}</div>}
        {title && <H3 className={styles.header}>{title}</H3>}

        {!!onCloseButton && (
          // <div className={styles.closeButtonHolder}>
          <button
            className={styles.closeButton}
            type="button"
            onClick={onCloseButton}
          >
            <Icons.CloseButton size="sm" />
          </button>
          // </div>
        )}

        {children}
        {buttons && (
          <div className={classNames(styles.buttons, classes?.buttons)}>
            {buttons.map((props, i) => (
              <Button key={i} {...props} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default Modal;
