'use client';

import { IUseQueue, useQueue } from '@axo/shared/hooks/useQueue';
import _ from 'lodash';
import {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  AnalyticsEvent,
  AnalyticsIdentify,
  AnalyticsServiceAsyncMethod,
  IAnalytics,
} from './Analytics.types';
import { IAnalyticsServiceParams } from './AnalyticsService.types';
import {
  AnalyticsServices,
  AnalyticsServicesRegistry,
} from './AnalyticsServices.registry';

type IAnalyticsContext = {
  services: IAnalytics[];
  queue: IUseQueue<AnalyticsServiceAsyncMethod>;
  isInitialized: boolean;
};

export const AnalyticsContext = createContext<IAnalyticsContext | null>(null);

const instantiateServices = (
  enabledServices: IAnalyticsProviderProps['enabledServices']
) =>
  enabledServices.map((service) =>
    typeof service === 'string'
      ? AnalyticsServicesRegistry[service]()
      : AnalyticsServicesRegistry[service.service](service.params)
  );

type IAnalyticsProviderProps = {
  children: ReactNode | ReactNode[];
  enabledServices: (
    | AnalyticsServices
    | { service: AnalyticsServices; params: IAnalyticsServiceParams }
  )[];
};

/**
 * @usage
 * ```tsx
 * <AnalyticsProvider enabledServices={['gtag']}>
 *   <App />
 * </AnalyticsProvider>
 * ```
 */
export const AnalyticsProvider = ({
  children,
  enabledServices,
}: IAnalyticsProviderProps): ReactElement => {
  const [services, setServices] = useState<IAnalytics[]>([]);
  const [isInitialized, setIsInitialized] = useState(false);
  const queue = useQueue<AnalyticsServiceAsyncMethod>();

  const currentEnabledServicesRef = useRef(enabledServices);

  useEffect(() => {
    const initializeServices = async () => {
      if (!_.isEqual(enabledServices, currentEnabledServicesRef.current)) {
        const servicesInst = instantiateServices(enabledServices);
        setServices(servicesInst);

        await Promise.allSettled(
          servicesInst.map((service) => service.waitForInitialization())
        );

        setIsInitialized(true);
      }
    };

    initializeServices();
    currentEnabledServicesRef.current = enabledServices;
  }, [enabledServices]);

  return (
    <AnalyticsContext.Provider value={{ services, queue, isInitialized }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

type IUseAnalytics = Required<Pick<IAnalytics, 'track' | 'identify' | 'reset'>>;

/**
 *
 * @usage
 * ```tsx
 * const analytics = useAnalytics();
 *
 * // ...
 * await analytics.track({
 *   event: 'Form Completed'
 * });
 * ```
 */
export const useAnalytics = (): IUseAnalytics => {
  const context = useContext(AnalyticsContext);
  if (context === null) {
    throw new Error('useAnalytics must be used within a AnalyticsProvider');
  }
  const { services, queue, isInitialized } = context;

  const runServiceMethod = async (
    service: IAnalytics,
    task: AnalyticsServiceAsyncMethod
  ) => {
    try {
      await task(service);
    } catch (e) {
      // filter out errors related to ad blockers
      if (
        e instanceof Error &&
        (/.*service is not present/i.test(e.message) ||
          /.*service timed out/i.test(e.message) ||
          /operation timed out/i.test(e.message))
      ) {
        console.warn('Analytics event processing failed...', e.message);
        return;
      }
      // re-throw other errors
      throw e;
    }
  };

  const processQueueItems = async () => {
    const tasks = [...queue.queue()];
    queue.clear();
    return await Promise.allSettled(
      tasks.map((task) =>
        services.map((service) => runServiceMethod(service, task))
      )
    );
  };

  const processQueue = useCallback(async () => {
    if (isInitialized && queue.size() > 0) {
      return processQueueItems();
    }
  }, [isInitialized, processQueueItems, queue]);

  useEffect(() => {
    processQueue();
  }, [services, isInitialized, processQueue]);

  const track = async (event: AnalyticsEvent) => {
    queue.add((service: IAnalytics) => service.track(event));
    await processQueue();
  };

  const identify = async (ai: AnalyticsIdentify) => {
    queue.add((service: IAnalytics) => service.identify?.(ai));
    await processQueue();
  };

  const reset = () => {
    services.map((service) => service.reset?.());
  };

  return {
    track,
    identify,
    reset,
  };
};
