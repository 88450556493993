import { Stepper } from '@axo/deprecated/util/ui-components';
import { MyPageFlags } from '@axo/mypage/util';
import { insurance } from '@axo/shared/data-access/types';
import { useFlags } from '@axo/shared/services/feature-flags';
import { ITrustpilot, Trustpilot } from '@axo/shared/ui/core';
import '@axo/shared/ui/core/styles/mixins.scss';
import clsx from 'clsx';
import { Dispatch } from 'react';
import { CurrentStep } from './CurrentStep';
import styles from './StepProvider.module.scss';
import { StepperAction } from './store/actions';
import { OnEnd, StepContext } from './store/context';
import { StepperState } from './store/reducer';
import { StepList } from './store/stepStates';

export type AppSpecificStepProviderProps = {
  onEnd: OnEnd;
  onError: (error: Error) => void;
  isEmbedded?: boolean;
  sourceTag: insurance.InsuranceSourceTag;
  classes?: {
    stepper?: string;
  };
};

export type StepProviderProps = AppSpecificStepProviderProps & {
  steps: StepList;
  state: StepperState;
  dispatch: Dispatch<StepperAction>;
  locale: 'fi' | 'no';
  trustpilotConfig: Pick<ITrustpilot, 'businessId' | 'locale' | 'link'>;
};

export function StepProvider({
  steps,
  onEnd,
  onError = () => undefined,
  isEmbedded = false,
  sourceTag,
  state,
  dispatch,
  locale,
  trustpilotConfig,
  classes,
}: StepProviderProps) {
  const {
    'show-new-insurance-modal': flag_show_new_insurance_modal,
    'new-insurance-flow-v2': flag_new_insurance_flow_v2,
  } = useFlags(MyPageFlags);

  const showStepperInCurrentStep =
    state.steps?.[state.selectedStep]?.isShownInStepper;

  return (
    <StepContext.Provider
      value={{ state, dispatch, onEnd, onError, isEmbedded, sourceTag }}
    >
      {!isEmbedded && showStepperInCurrentStep && (
        <div className={styles.stepper}>
          <Stepper
            locale={locale}
            steps={Object.values(state.steps).filter(
              (step) => step.isShownInStepper
            )}
            hideHeadline
          />
        </div>
      )}
      <div className={isEmbedded ? styles.contentFull : styles.contentSplit}>
        <div
          className={clsx(
            styles.mainColumn,
            isEmbedded && styles.background,
            flag_show_new_insurance_modal &&
              !flag_new_insurance_flow_v2 &&
              styles.mainColumnGap,
            flag_new_insurance_flow_v2 && classes?.stepper
          )}
        >
          {state.error !== undefined && steps.error !== undefined ? (
            steps.error
          ) : (
            <CurrentStep
              name={state.selectedStep}
              component={steps[state.selectedStep]}
            />
          )}
        </div>
        {!isEmbedded && (
          <div className={styles.trustpilot}>
            <Trustpilot
              templateId="53aa8807dec7e10d38f59f32"
              height="150px"
              {...trustpilotConfig}
            />
          </div>
        )}
      </div>
    </StepContext.Provider>
  );
}
