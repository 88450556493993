import { useCreateCustomer as createCustomerQuery } from '@axo/shared/data-access/hooks';
import { DataAccessContext } from '@axo/shared/data-access/provider';
import { useContext, useEffect } from 'react';

export const useUser = () => {
  const { state: userState, dispatch } = useContext(DataAccessContext);
  const createCustomer = createCustomerQuery();

  const search = new URLSearchParams(window.location.search);
  const hash = new URLSearchParams(window.location.hash.replace(/^#/, ''));

  // Create Customer on 1P if it doesn't exists -> DK Legacy Flow
  const email = search.get('email');
  useEffect(() => {
    let ignore = false || !!userState.user.token;

    if (!email || ignore) return;
    createCustomer.mutateAsync({ Email: email }).then((res) => {
      const token = res.JWT;

      if (!token) {
        throw new Error('No Token found on Customer');
      }

      dispatch({
        type: 'Set auth',
        scope: { parentType: 'user' },
        payload: {
          JWT: token,
        },
      });
    });

    return () => {
      ignore = true;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  const token = hash.get('token');
  useEffect(() => {
    let ignore = false || !!userState.user.token;

    if (!token || ignore) return;

    dispatch({
      type: 'Set auth',
      scope: { parentType: 'user' },
      payload: {
        JWT: token,
      },
    });

    return () => {
      ignore = true;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const legacyToken = search.get('token');
  useEffect(() => {
    let ignore = false || !!userState.user.legacyToken;

    if (!legacyToken || ignore) return;

    dispatch({
      type: 'Set legacy auth',
      scope: { parentType: 'user' },
      payload: {
        legacyToken,
      },
    });

    return () => {
      ignore = true;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legacyToken]);

  if (!token && !legacyToken && !search.get('m')) {
    return {
      error: new Error(
        'Cannot Fetch User: No Token, Legacy Token or Magic Token provided on the URL'
      ),
    };
  }

  return {
    user: userState.user,
  };
};
